import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { depositeApi } from "./depositeApi";

interface DepositeState {
  depositeData: any;
}

const initialState: DepositeState = {
  depositeData: {},
};

export const depositeSlice = createSlice({
  name: "deposite",
  initialState,
  reducers: {
    clearDeposite:() => initialState
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      depositeApi.endpoints.getDeposite.matchFulfilled,
      (state, { payload }) => {
        state.depositeData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default depositeSlice.reducer;
export const {clearDeposite} = depositeSlice.actions

export const selectDepositeData = (state: RootState) =>
  state.deposite.depositeData;
export const useDepositeData = () => {
  const depoData = useSelector(selectDepositeData);
  return useMemo(() => depoData, [depoData]);
};
