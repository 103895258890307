import { api } from "../../utills/api";

export const settingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSettingDetails: build.query<{ data: any }, void>({
      query: () => "/setting/component",
      providesTags:["Setting Data"]
    }),
    updateSettingSummary: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/setting/change-password",
        body: params,
        method: "POST",
      }),
    }),
    updateSettingWebSummary: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/setting/update/Notify",
        body: params,
        method: "POST",
      }),
      invalidatesTags:["Setting Data"]
    }),
  }),
});

export const {
  useGetSettingDetailsQuery,
  useUpdateSettingSummaryMutation,
  useUpdateSettingWebSummaryMutation,
} = settingApi;

export const {
  endpoints: {
    getSettingDetails,
    updateSettingSummary,
    updateSettingWebSummary,
  },
} = settingApi;
