import { useLocation } from "react-router";

// UI IMPORT
import { Avatar, Icon, Select } from "../../ui";

// PROJECT IMPORT
import { useUser } from "../auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../utills/hooks";
import { toggleDrawer } from "../common/commonSlice";
import { logout } from "../../helperFunctions";
import { AiOutlineSetting, AiOutlineLogout } from "react-icons/ai";
import { useEffect, useState } from "react";
import { getAllCountries, getAllTimezones } from "countries-and-timezones";
import moment from "moment";

import { timezoneNames, ZonedDate } from "@progress/kendo-date-math";
import "@progress/kendo-date-math/tz/all";

const Header = () => {
  const [isMobileMenu, setIsMobileMenu] = useState(false);

  const user = useUser();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const timezones = timezoneNames()
    .filter((l) => l.indexOf("/") > -1)
    .sort((a, b) => a.localeCompare(b));

  const [timezone, setTimezone] = useState("Asia/Calcutta");
  const [result, setResult] = useState<any>(null);

  useEffect(() => {
    tick();
    const interval = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timezone]);

  const tick = () => {
    const result: any = ZonedDate.fromLocalDate(new Date(), timezone);
    setResult(result);
  };

  const handleTimezoneChange = (event: any) => {
    setTimezone(event.target.value);
  };

  const data = user?.sidebar?.find(
    (item: any) => item?.route === location?.pathname
  );

  return (
    <>
      <div className="mainNavbar webNav  fixed-top sticky -top-1 z-10 bg-[#f2f3f8] border-2 border-[#f2f3f8] ">
        <div className="navBar boxBetween flex justify-between items-center">
          <div className="navToggle title-nav flex">
            <Icon
              className="me-2"
              name="MenuSidebar"
              onClick={() => {
                dispatch(toggleDrawer());
              }}
            />
            {data?.name || ""}
          </div>

          <div className="navIcons  boxBetween flex items-center md:items-start sm:hidden md:hidden 2sm:hidden">
            <div className="md:my-2">
              <div className="!w-fit  !bg-[#E9E9FF]  rounded-xl me-3 p-1.5 ps-3">
                <div className="flex items-center  rounded-lg  ">
                  <div>
                    <Select
                      className="!w-40 me-1 h-9"
                      placeholder="Select Country"
                      name="country"
                      options={timezones.map((zone) => ({
                        value: zone,
                        label: zone,
                      }))}
                      value={timezone}
                      onChange={handleTimezoneChange}
                    />
                    {/* <select onChange={handleTimezoneChange} value={timezone}>
                    {timezones.map((zone, i) => (
                      <option key={i}>{zone}</option>
                    ))}
                  </select> */}
                  </div>
                  <p className="border-2  !bg-[#fff] rounded-lg p-[6px] text-sm">
                    {result && result?.toString()}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center md:my-2  md:justify-end">
              {user?.is_admin === "merchant" ? (
                <>
                  <div
                    onClick={() => {
                      navigate("setting/component");
                    }}
                    className="flex gap-2 me-3"
                    title="Profile"
                  >
                    <Avatar
                      className="!w-10 !h-10 !bg-[#E9E9FF]"
                      name={user?.userData?.fullName || "U"}
                    />
                  </div>

                  <div
                    className="flex items-center justify-center me-3 bg-[#E9E9FF] rounded-full h-[40px] w-[40px]"
                    title="Setting"
                  >
                    <AiOutlineSetting
                      className="text-ev-darkblue text-xl"
                      onClick={() => {
                        navigate("setting/component");
                      }}
                    />
                  </div>
                </>
              ) : null}
              <div
                className="flex items-center justify-center bg-[#E9E9FF] rounded-full h-[40px] w-[40px]"
                title="Logout"
                onClick={async () => {
                  try {
                    await logout(dispatch);
                    navigate("/");
                  } catch (err) {
                    console.error("Logout Error =-=>", err);
                  }
                }}
              >
                <AiOutlineLogout className="text-ev-darkblue text-xl" />
              </div>
            </div>
          </div>
          <div className="lg:hidden xl:hidden 2xl:hidden">
            <div
              onClick={() => setIsMobileMenu(!isMobileMenu)}
              className="flex items-center justify-center bg-[#E9E9FF] rounded-full h-[40px] w-[40px]"
            >
              <Icon name="MoreItem" />
            </div>
          </div>
        </div>
        <div className="lg:hidden xl:hidden 2xl:hidden">
          {isMobileMenu ? (
            <div className="navIcons  boxBetween flex items-center justify-between">
              <div className="md:my-2">
                <div className="!w-fit  !bg-[#E9E9FF]  rounded-xl me-3 p-1.5 ps-3">
                  <div className="flex items-center  rounded-lg  ">
                    <div>
                      <Select
                        className="!w-40 me-1 h-9"
                        placeholder="Select Country"
                        name="country"
                        options={timezones.map((zone) => ({
                          value: zone,
                          label: zone,
                        }))}
                        value={timezone}
                        onChange={handleTimezoneChange}
                      />
                    </div>
                    <p className="border-2  !bg-[#fff] rounded-lg p-[6px] text-sm">
                      {result && result?.toString()}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center md:my-2  md:justify-end">
                {user?.is_admin === "merchant" ? (
                  <>
                    <div
                      onClick={() => {
                        navigate("setting/component");
                      }}
                      className="flex gap-2 me-3"
                      title="Profile"
                    >
                      <Avatar
                        className="!w-10 !h-10 !bg-[#E9E9FF]"
                        name={user?.userData?.fullName || "U"}
                      />
                    </div>

                    <div
                      className="flex items-center justify-center me-3 bg-[#E9E9FF] rounded-full h-[40px] w-[40px]"
                      title="Setting"
                    >
                      <AiOutlineSetting
                        className="text-ev-darkblue text-xl"
                        onClick={() => {
                          navigate("setting/component");
                        }}
                      />
                    </div>
                  </>
                ) : null}
                <div
                  className="flex items-center justify-center bg-[#E9E9FF] rounded-full h-[40px] w-[40px]"
                  title="Logout"
                  onClick={async () => {
                    try {
                      await logout(dispatch);
                      navigate("/");
                    } catch (err) {
                      console.error("Logout Error =-=>", err);
                    }
                  }}
                >
                  <AiOutlineLogout className="text-ev-darkblue text-xl" />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Header;
