import { useMemo } from "react";

// PROJECT IMPORT
import { RootState } from "../../utills/store";
import { dashboardApi } from "./dashboardApi";

// THIRD - PARTY IMPORT
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

interface DashboardState {
  depositChartData: any;
  withdrawalChartData: any;
  summaryItems: any;
}

const initialState: DashboardState = {
  depositChartData: {},
  withdrawalChartData: {},
  summaryItems: {},
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearDashboard: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      dashboardApi.endpoints.getSummary.matchFulfilled,
      (state, { payload }) => {
        state.summaryItems = payload?.data || {};
      }
    );
    builder.addMatcher(
      dashboardApi.endpoints.getDepositChart.matchFulfilled,
      (state, { payload }) => {
        state.depositChartData = payload?.data || {};
      }
    );
    builder.addMatcher(
      dashboardApi.endpoints.getWithdrawalChart.matchFulfilled,
      (state, { payload }) => {
        state.withdrawalChartData = payload?.data || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default dashboardSlice.reducer;
export const { clearDashboard } = dashboardSlice.actions;

export const selectDepositChart = (state: RootState) =>
  state.dashboard.depositChartData;
export const useDepositChart = () => {
  const depositChartData = useSelector(selectDepositChart);
  return useMemo(() => depositChartData, [depositChartData]);
};

export const selectWithdrawalChart = (state: RootState) =>
  state.dashboard.withdrawalChartData;
export const useWithdrawalChart = () => {
  const withdrawalChartData = useSelector(selectWithdrawalChart);
  return useMemo(() => withdrawalChartData, [withdrawalChartData]);
};

export const selectSummaryItems = (state: RootState) =>
  state.dashboard.summaryItems;
export const useSummaryItems = () => {
  const summaryItems = useSelector(selectSummaryItems);
  return useMemo(() => summaryItems, [summaryItems]);
};
