import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { bulkUploadApi } from "./bulkUploadApi";

interface BulkUploadState {
  bulkUploadData: any;
  editUploadState: any;
}

const initialState: BulkUploadState = {
  bulkUploadData: {},
  editUploadState: [],
};

export const bulkUploadSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    clearBulkUpload: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bulkUploadApi.endpoints.getBulkUpload.matchFulfilled,
      (state, { payload }) => {
        state.bulkUploadData = payload || {};
      }
    );
    builder.addMatcher(
      bulkUploadApi.endpoints.editBulkUpload.matchFulfilled,
      (state, { payload }) => {
        state.editUploadState = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default bulkUploadSlice.reducer;
export const { clearBulkUpload } = bulkUploadSlice.actions;

export const selectBulkUploadData = (state: RootState) =>
  state.bulkUpload.bulkUploadData;
export const useBulkUploadData = () => {
  const bulkUploadData = useSelector(selectBulkUploadData);
  return useMemo(() => bulkUploadData, [bulkUploadData]);
};

export const selectEditUploadState = (state: RootState) =>
  state.bulkUpload.editUploadState;
export const useEditUploadState = () => {
  const editUploadState = useSelector(selectEditUploadState);
  return useMemo(() => editUploadState, [editUploadState]);
};
