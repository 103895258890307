import { api } from "../../utills/api";

export const depositeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDeposite: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/deposit",
        body: params,
        method: "POST",
      }),
    }),

    ResendDepositWebhook: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/deposit/ResendWebhook",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetDepositeMutation, useResendDepositWebhookMutation } =
  depositeApi;
export const {
  endpoints: { getDeposite, ResendDepositWebhook },
} = depositeApi;
