import { useMemo } from "react";

// PROJECT IMPORT
import { RootState } from "../../utills/store";
import { statementApi } from "./statementApi";

// THIRD - PARTY IMPORT
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

interface SettingState {
  statementData: any;
}

const initialState: SettingState = {
  statementData: {},
};

export const statementSlice = createSlice({
  name: "statement",
  initialState,
  reducers: {
    clearStatement: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      statementApi.endpoints.getStatementData.matchFulfilled,
      (state, { payload }) => {
        state.statementData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default statementSlice.reducer;
export const { clearStatement } = statementSlice.actions;

export const selectStatementData = (state: RootState) =>
  state.statement.statementData;
export const useStatementData = () => {
  const details = useSelector(selectStatementData);
  return useMemo(() => details, [details]);
};
