import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// PROJECT IMPORT
import { persistor, store } from "./utills/store";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "tailwindcss/tailwind.css";
import "./index.css";
import "flatpickr/dist/themes/material_green.css";

// THIRD - PARTY IMPORT
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { MediaProvider } from "./components";
import { unregister } from "./registerServiceWorker";
unregister();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <MediaProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </MediaProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
