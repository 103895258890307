import React, { useState } from "react";
import { Button, Input, Loader } from "../../ui";
import { useGetClaimutrDataMutation } from "./claimutrApi";
import { showToaster } from "../../helperFunctions";

// THIRD - PARTY IMPORT
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const ClaimutrModel = (props: any) => {
  const { isOpen, onClose, values, isUtr } = props;

  const [isLoader, setIsLoader] = useState(false);
  const [getClaimutrData] = useGetClaimutrDataMutation();

  const basicSchema = yup.object({
    payment_ref_id: yup.string().required("Please Enter Deposit ID"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<any>({
    values: {
      payment_ref_id: isUtr?.traId?.toString(),
      utr: values?.utr,
      amount: isUtr?.amount,
    },
    resolver: yupResolver(basicSchema),
  });

  const onSubmit = async (values: any) => {
    setIsLoader(true);

    const res: any = await getClaimutrData(values);

    if (res?.data?.status == false) {
      showToaster(res?.data?.message || "", "Error");
      setIsLoader(false);
    } else {
      showToaster(res?.data?.message);
      onClose();
      reset();
      setIsLoader(false);
    }
  };

  if (!isOpen) return null;

  return (
    <form
      className="fixed inset-0 z-50 flex items-center justify-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          onClose();
        }}
      />

      <div className="modal-container bg-white min-w-[500px] md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-4 text-left ">
          <div className="flex justify-between items-center pb-3 mx-6 border-b-2">
            <p className="text-2xl font-bold text-ev-darkblue">Claim UTR</p>
            <div className="modal-close cursor-pointer z-50" onClick={onClose}>
              <svg
                className="fill-current text-black"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path d="M18 1.3L16.7 0 9 7.7 1.3 0 0 1.3 7.7 9 0 16.7 1.3 18 9 10.3l7.7 7.7 1.3-1.3L10.3 9z"></path>
              </svg>
            </div>
          </div>

          <div className="px-6 pt-3">
            <div className="my-2">
              <div className="flex flex-col item-center">
                {!isUtr?.is ? (
                  <Input
                    className="me-2 w-full"
                    name="payment_ref_id"
                    placeholder="Enter Payment Ref ID"
                    label="Payment Ref ID"
                    register={register}
                    errors={errors}
                  />
                ) : null}
                <div className="flex justify-around w-100 mt-4 gap-4">
                  {isUtr?.is ? (
                    <div className="w-full">
                      <h4 className="text-chatlook-darkblue text-md font-bold">
                        Deposit ID
                      </h4>
                      <p className="mt-2 bg-[#dadada88] p-2 rounded-md">
                        {isUtr?.traId}
                      </p>
                    </div>
                  ) : null}
                  <div className="w-full">
                    <h4 className="text-chatlook-darkblue text-md font-bold">
                      Bank UTR
                    </h4>
                    <p className="mt-2 bg-[#dadada88] p-2 rounded-md">
                      {values?.utr}
                    </p>
                  </div>
                  <div className="w-full">
                    <h4 className="text-chatlook-darkblue text-md font-bold">
                      Amount
                    </h4>
                    <p className="mt-2 bg-[#dadada88] p-2 rounded-md">
                      {isUtr?.amount ? isUtr?.amount : 0}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-8">
                {isUtr?.is ? (
                  <Button type="button" className="!w-full" disabled>
                    Already Used
                  </Button>
                ) : !isLoader ? (
                  <Button type="submit">Claim Now</Button>
                ) : (
                  <div className="flex item-center justify-center">
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ClaimutrModel;
