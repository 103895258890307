import { api } from "../../utills/api";

export const statementApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStatementData: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/statement",
        body: params,
        method: "POST",
      }),
    }),
    getGenerateStatement: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/statement/generatestatement",
        body: params,
        method: "POST",
      }),
    }),
  }),
});
export const {
  useGetStatementDataMutation,
  useGetGenerateStatementMutation
} = statementApi;

export const {
  endpoints: {
    getStatementData,
    getGenerateStatement
  },
} = statementApi;
