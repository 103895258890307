export const filterOptions = [
  { value: "UTR", label: "UTR" },
  { value: "CustEmail", label: "Customer Email" },
  { value: "CustMobile", label: "Mobile Number" },
  { value: "WithdrawalAmt", label: "Withdrawal Amount" },
  { value: "WithdrawalId", label: "Withdrawal ID" },
  { value: "RefId", label: "Oreder ID" },
];

export const withdrawalSummary = [
  {
    imgSrc: "DashboardDeposite",
    key: "total_no_of_withdrawal",
    text: "Total No. of Withdrawal",
    rupee:false,
  },
  {
    imgSrc: "DashboardWithdrawal",
    key: "withdrawal_Amount",
    text: "Withdrawal Amount",
  },
  {
    imgSrc: "DashboardUnsettled",
    key: "total_fees",
    text: "Total Fees",
  },
  {
    imgSrc: "DashboardActiveBalance",
    key: "total_withdrawal_Amount",
    text: "Total Withdrawal Amount",
  },
];

export const tableData: any[] = [
  {
    id: 1,
    bank_details: {
      account_holder_name: "John Doe",
      ifsc_code: "ABCD1234",
      upi_id: "john.doe@upi",
    },
    account_number: "1234567890",
  },
  {
    id: 2,
    bank_details: {
      account_holder_name: "Jane Smith",
      ifsc_code: "EFGH5678",
      upi_id: "jane.smith@upi",
    },
    account_number: "9876543210",
  },
];

export const withdrawalType = [
  { value: "IMPS", label: "IMPS" },
  { value: "NEFT", label: "NEFT" },
  { value: "RTGS", label: "RTGS" },
];
