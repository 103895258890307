import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { usersApi } from "./usersApi";

interface UsersState {
  usersDataState: any;
}

const initialState: UsersState = {
  usersDataState: {},
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearUsers:() => initialState

  },
  extraReducers: (builder) => {
    builder.addMatcher(
        usersApi.endpoints.getUsers.matchFulfilled,
      (state, { payload }) => {
        state.usersDataState = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default usersSlice.reducer;
export const {clearUsers} = usersSlice.actions

export const selectUsersData = (state: RootState) =>
  state.users.usersDataState;
export const useUsersData = () => {
  const usersData = useSelector(selectUsersData);
  return useMemo(() => usersData, [usersData]);
};
