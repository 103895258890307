import React, { useState } from "react";
import { Button, Icon, Input, Loader } from "../../ui";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetInternalFundTransferMutation } from "./fundTransferApi";
import { useForm } from "react-hook-form";
import { showToaster } from "../../helperFunctions";

const FundTransfer = () => {
  const [getInternalFundTransfer] = useGetInternalFundTransferMutation();
  const [isLoader, setIsLoader] = useState(false);

  const schema = yup.object({
    to_account: yup.string().required("Please enter a valid email address"),
    amount: yup.string().required("Please enter amount"),
  });

  const {
    getValues,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async () => {
    try {
      setIsLoader(true);

      const values = getValues();
      const res: any = await getInternalFundTransfer(values);

      if (res?.data?.status) {
        showToaster(res?.data?.message);
        setIsLoader(false);
        reset();
      } else {
        setIsLoader(false);
      }
    } catch (e) {
      setIsLoader(false);
    }
  };

  return (
    <>
      <form
        className="flex flex-wrap gap-2 items-start px-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          className="me-2"
          name="to_account"
          placeholder="Enter Email ID"
          label="Email ID"
          register={register}
          errors={errors}
        />
        <Input
          type="number"
          className="me-2"
          name="amount"
          placeholder="Enter Amount"
          label="Amount"
          register={register}
          errors={errors}
        />

        {!isLoader ? (
          <Button type="submit" className="mt-[22px] !h-9 !text-sm">
            Send
          </Button>
        ) : (
          <div className="flex item-center justify-center mt-6 ">
            <Loader />
          </div>
        )}
      </form>
      <div className="mt-20">
        <Icon name="FundtransferHero" className="m-auto opacity-70 max-w-[50vw]" />
      </div>
    </>
  );
};

export default FundTransfer;
