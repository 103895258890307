import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";

interface CommonState {
    isDrawerOpen:boolean;
}
const initialState:CommonState = {
    isDrawerOpen: true,
};

export const commonSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        toggleDrawer: (state:CommonState, { payload }:PayloadAction<boolean | undefined>) => {
            state.isDrawerOpen = payload === undefined ? !state.isDrawerOpen : payload;
        },
        clearCommon:() => initialState

    },
});

// Action creators are generated for each case reducer function
export default commonSlice.reducer;
export const { toggleDrawer,clearCommon } = commonSlice.actions;

export const selectIsDrawerOpen = (state:RootState) => state.common.isDrawerOpen;
export const useIsDrawerOpen = () => {
    const isDrawerOpen = useSelector(selectIsDrawerOpen);
    return useMemo(() => isDrawerOpen, [isDrawerOpen]);
};
