import { useMemo } from 'react'

// PROJECT IMPORT
import { RootState } from '../../utills/store'
import {settingApi} from './SettingApi'

// THIRD - PARTY IMPORT
import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

interface SettingState {
    details:any;
}

const initialState:SettingState = {
    details:{}
}

export const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
    clearSetting:() => initialState
    },
    extraReducers:(builder) => {
        builder.addMatcher(settingApi.endpoints.getSettingDetails.matchFulfilled,(state,{payload}) => {
            state.details = payload?.data || {}
        })
    }
})

// Action creators are generated for each case reducer function
export default settingSlice.reducer
export const {clearSetting} = settingSlice.actions

export const selectSettingDetails = (state:RootState) => state.setting.details
export const useSettingDetails = () => {
    const details = useSelector(selectSettingDetails)
    return useMemo(() => details, [details])
}