import { api } from "../../utills/api";

export const claimutrApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUnClaimutrData: build.query<{ data: any }, void>({
      query: () => "utr/unClaimUtr",
    }),
    getClaimutrData: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/utr/claimUtr",
        body: params,
        method: "POST",
      }),
    }),
    checkClaimData: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/utr/checkUtr",
        body: params,
        method: "POST",
      }),
    }),
  }),
});
export const {
  useGetUnClaimutrDataQuery,
  useGetClaimutrDataMutation,
  useCheckClaimDataMutation,
} = claimutrApi;

export const {
  endpoints: { getUnClaimutrData, getClaimutrData, checkClaimData },
} = claimutrApi;
