import { api } from "../../utills/api";

export const bulkUploadApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBulkUpload: build.query<{ data: any }, any>({
      query: (params) => ({
        url: "/withdrawal/bulk/files",
        params: params,
      }),
      providesTags: ["Bulk Upload"],
    }),
    uploadBulkUpload: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/withdrawal/bulk/request",
        body: params,
        method: "POST",
      }),
      invalidatesTags: ["Bulk Upload"],
    }),
    downloadBulkUpload: build.query<{ data: any }, any>({
      query: (params) => ({
        url: `/withdrawal/bulk/file/${params}`,
        method: "GET",
      }),
      providesTags: ["Bulk Upload"],
    }),
    editBulkUpload: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/withdrawal/edit/bulk/request",
        body: params,
        method: "POST",
      }),
      invalidatesTags: ["Bulk Upload"],
    }),
    updateBulkUpload: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/withdrawal/update/bulk/request",
        body: params,
        method: "POST",
      }),
      invalidatesTags: ["Bulk Upload"],
    }),
  }),
});

export const {
  useLazyGetBulkUploadQuery,
  useUploadBulkUploadMutation,
  useLazyDownloadBulkUploadQuery,
  useEditBulkUploadMutation,
  useUpdateBulkUploadMutation,
} = bulkUploadApi;

export const {
  endpoints: {
    getBulkUpload,
    uploadBulkUpload,
    downloadBulkUpload,
    editBulkUpload,
    updateBulkUpload,
  },
} = bulkUploadApi;
