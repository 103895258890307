import { Icon, IconKeys } from "../icons";
import { OptionTypes } from "../theme";

interface SelectProps {
  className?: string;
  lableClassName?: string;
  name: string;
  label?: string;
  value?: any;
  options: OptionTypes[];
  icon?: IconKeys;
  placeholder?: string;
  errors?: any;
  register?: any;
  isDefaultOption?: boolean;
  onChange?: (e?: any) => void;
}

const Select = (props: SelectProps) => {
  const {
    className = "",
    label,
    icon,
    options = [],
    errors,
    register,
    placeholder = "Select ",
    name,
    lableClassName = "",
    isDefaultOption = true,
    ...rest
  } = props;

  const getRegister = (register: any) =>
    register && name ? { ...register?.(name) } : {};

  const message = errors?.[name]?.message || "";
  return (
    <div className={`${className} w-fit flex flex-col relative`}>
      {label ? (
        <label className={`${lableClassName} text-sm text-ev-darkblue `}>{label}</label>
      ) : null}
      <div className="flex flex-col justify-center items-center select-img">
        {icon ? (
          <Icon className="absolute me-4 text-xl ms-4" name={icon} />
        ) : null}
        <select
          name={name}
          id={name}
          className={`${className} text-sm ${message ? "border-red-500" : ""} ${
            icon ? "pl-11" : "pl-3"
          } pr-7 appearance-none w-full text-ev-lightblue bg-chatlook-white me-2 h-10 rounded-md outline-none border-2 focus:border-chatlook-darkblue bg-opacity-5`}
          {...getRegister(register)}
          {...rest}
        >
          {isDefaultOption ? <option value="">{placeholder}</option> : null}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {/* <Icon className="absolute right-0 me-4 text-xl" name="ChevronDown" /> */}
      </div>
      {message ? <span className="text-red-500 text-sm">{message}</span> : null}
    </div>
  );
};

export default Select;
