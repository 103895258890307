import React from "react";

interface AvatarProps {
    name: string;
    className?:string;
}
const Avatar = (props: AvatarProps) => {
    const { name,className } = props;

    const names = name?.split(" ")
    const first = names?.[0]?.slice(0,1)?.toUpperCase()
    const second = names?.[1]?.slice(0,1)?.toUpperCase()

    return <div className={`${className} rounded-full bg-white h-12 w-12 flex items-center justify-center font-bold text-xl`}>{first}{second}</div>;
};

export default Avatar;
