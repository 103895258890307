import { useEffect, useState, Fragment } from "react";

// UI IMPORT
import {
  Avatar,
  Button,
  CheckBox,
  Icon,
  Input,
  Loader,
  Password,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import {
  useAddUsersMutation,
  useGetUsersMutation,
  useUpdateUsersMutation,
} from "./usersApi";
import { useUsersData } from "./usersSlice";
import { showToaster } from "../../helperFunctions";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUser } from "../auth/authSlice";

const initialData = [
  {
    id: "dashboard",
    names: ["Dashboard"],
    parents: ["Report", "Statement", "Balance Sheet"],
    name: "Dashboard",
    value: "dashboard",
    label: "Dashboard",
  },
  {
    id: "deposit",
    name: "Deposit",
    names: ["Deposit"],
    parents: ["Report", "Statement", "Balance Sheet", "Claim UTR"],
    value: "deposit",
    label: "Deposit",
  },
  {
    id: "withdrawal",
    names: ["Withdrawal"],
    parents: ["Report", "Statement", "Balance Sheet"],
    name: "Withdrawal",
    value: "withdrawal",
    label: "Withdrawal",
  },
  {
    id: "claimutr",
    names: ["Claim UTR", "Deposit"],
    blockChild: ["Deposit"],
    name: "Claim UTR",
    value: "claimutr",
    label: "Claim UTR",
  },
  {
    id: "bulkupload",
    names: ["Bulk Upload", "Withdrawal"],
    blockChild: ["Withdrawal"],
    name: "Bulk Upload",
    value: "bulkupload",
    label: "Bulk Upload",
  },
  {
    id: "statement",
    names: [
      "Statement",
      "Balance Sheet",
      "Report",
      "Withdrawal",
      "Deposit",
      "Dashboard",
    ],
    name: "Statement",
    value: "statement",
    label: "Statement",
  },
  {
    id: "balancesheet",
    names: [
      "Statement",
      "Balance Sheet",
      "Report",
      "Withdrawal",
      "Deposit",
      "Dashboard",
    ],
    name: "Balance Sheet",
    value: "balancesheet",
    label: "Balance Sheet",
  },
  {
    id: "fundtransfer",
    names: ["Internal Fund Transfer"],
    name: "Internal Fund Transfer",
    value: "fundtransfer",
    label: "Internal Fund Transfer",
  },
  {
    id: "report",
    names: [
      "Statement",
      "Balance Sheet",
      "Report",
      "Withdrawal",
      "Deposit",
      "Dashboard",
    ],
    name: "Report",
    value: "report",
    label: "Report",
  },
];

const Users = () => {
  const user = useUser();

  const [getUsers, { isLoading }] = useGetUsersMutation();
  const [isLoader, setIsLoader] = useState(false);

  const [addUser] = useAddUsersMutation();
  const [updateUsers] = useUpdateUsersMutation();

  const userData = useUsersData();

  const [isEdit, setIsEdit] = useState<any>();
  const [data, setData] = useState<any[]>(initialData);

  const basicSchema = yup.object({
    user_name: yup.string().required("Please Enter Username"),
    password: yup.string().required("Please Enter Password"),
  });

  const [schema, setSchema] = useState<any>(basicSchema);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    clearErrors,
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const onGetData = () => {
    getUsers({});
  };

  useEffect(() => {
    if (isEdit) {
      setSchema(yup.object({}));
    } else {
      setSchema(basicSchema);
    }
    // eslint-disable-next-line
  }, [isEdit, setError, clearErrors]);

  const onSubmit = async (values: any) => {
    const permissions = data
      ?.filter((item) => item?.checked)
      ?.map((item) => item?.name);
    if (isEdit) {
      try {
        setIsLoader(true);
        const res: any = await updateUsers({
          ...values,
          permission: permissions,
        }).unwrap();
        showToaster(res?.message);
        onGetData();
        reset({
          user_name: "",
          password: "",
        });
        setData(initialData);
        setIsEdit(false);
        setIsLoader(false);
      } catch (err) {
        console.error("Update User Error =-=>", err);
        setIsLoader(false);
      }
    } else {
      try {
        setIsLoader(true);
        const res: any = await addUser({
          ...values,
          permission: permissions,
        }).unwrap();
        showToaster(res?.message);
        onGetData();
        reset({
          user_name: "",
          password: "",
        });
        setData(initialData);
        setIsEdit(false);
        setIsLoader(false);
      } catch (err) {
        console.error("Create User Error =-=>", err);
        setIsLoader(false);
      }
    }
  };

  const columns = [
    {
      title: "Username",
      name: "user_name",
      Cell: (data: any) => {
        return (
          <div className="flex gap-3 items-center">
            <Avatar
              name={data?.user_name || ""}
              className="border border-blue-600"
            />
            <span>{data?.user_name || ""}</span>
          </div>
        );
      },
    },
    // {
    //   title: "Email ID",
    //   name: "email_id",
    // },
    {
      title: "Edit",
      Cell: (data: any) => {
        return (
          <div
            className="flex items-center justify-center me-4 bg-[#E9E9FF] rounded-full h-[36px] w-[36px] cursor-pointer"
            onClick={() => {
              const newData = initialData?.map((item) => ({
                ...item,
                checked: !!data?.permission?.includes(item?.name),
              }));
              reset(data);
              setData(newData);
              setIsEdit(data?.id);
            }}
          >
            <Icon name="Edit" />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <form
        className="flex flex-wrap gap-2 items-start px-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          name="user_name"
          placeholder="Enter Username"
          disabled={!!isEdit}
          label="Username"
          register={register}
          errors={errors}
        />
        <Password
          disabled={!!isEdit}
          name="password"
          label="Password"
          placeholder="Enter Password"
          register={register}
          errors={errors}
        />
        {!isLoader ? (
          <Button type="submit" className="mt-[22px] !h-9 !text-sm">
            {!isEdit ? "Add" : "Update"}
          </Button>
        ) : (
          <div className="flex item-center justify-center mt-6">
            <Loader />
          </div>
        )}
      </form>
      <div>
        <h6 className="mb-2 mt-2">
          <b>Select Access</b>
        </h6>
        <div className="flex gap-4 flex-wrap">
          {data
            ?.filter(
              (item: any) =>
                user?.sidebar?.findIndex(
                  (val: any) => val?.name === item?.name
                ) > 0
            )
            ?.map((val: any, i: number, array: any[]) => {
              return (
                <Fragment key={i}>
                  <CheckBox
                    className="accent-chatlook-darkblue"
                    label={val?.label}
                    id={val?.id}
                    name={val?.name}
                    value={val?.value}
                    checked={!!val?.checked}
                    onChange={(e) => {
                      const allNames = array?.map((item) => item?.name);
                      if (e.target.checked) {
                        if (val?.names?.length > 1) {
                          showToaster("All required permissions selected");
                        }
                        const newData = data?.map((item) => ({
                          ...item,
                          checked:
                            val?.names?.includes(item?.name) &&
                              allNames?.includes(item?.name)
                              ? true
                              : !!item?.checked,
                        }));
                        setData([...newData]);
                      } else {
                        if (val?.parents?.length) {
                          const index =
                            data
                              ?.filter((item) => item?.checked)
                              ?.findIndex((item) =>
                                val?.parents?.includes(item?.name)
                              ) || -1;
                          if (index > -1) {
                            return;
                          }
                        }
                        const newData = data?.map((item) => ({
                          ...item,
                          checked:
                            val?.names?.includes(item?.name) &&
                              !val?.blockChild?.includes(item)
                              ? false
                              : !!item?.checked,
                        }));
                        setData([...newData]);
                      }
                    }}
                  />
                </Fragment>
              );
            })}
        </div>
      </div>

      <div className="App mt-3">
        <Table
          columns={columns}
          isLoading={isLoading}
          data={userData?.data}
          isExpendable={false}
        >
          {userData?.data?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow columns={columns} item={item} isExpendable={false} />
              </Fragment>
            );
          })}
        </Table>
      </div>
    </>
  );
};

export default Users;
