import { PropsWithChildren } from "react";
import { ButtonTypes, ButtonVariants, getButtonVariants } from "../theme";

interface ButtonProps extends PropsWithChildren<{}> {
  className?: string;
  type?: ButtonTypes;
  variant?: ButtonVariants;
  href?: any;
  download?: string;
  target?: string;
  disabled?: boolean;
  title?:string;
  onClick?: () => void;
}
const Button = (props: ButtonProps) => {
  const {
    className,
    type = "button",
    download,
    variant = "Primary",
    href,
    disabled,
    children,
    title,
    ...rest
  } = props;

  const buttonClassName = getButtonVariants(variant);
  return (
    <>
      {href ? (
        <a
          href={href}
          className={`${className} ${buttonClassName} ${!disabled ? 'appl-btn' : 'px-4'} rounded-md h-10 flex items-center justify-center`}
          {...rest}
        >
          {children}
        </a>
      ) : (
        <button
          type={type}
          title={title}
          disabled={disabled}
          className={`${className} ${buttonClassName} ${
            disabled ? "bg-opacity-60" : ""
          } ${!disabled ? 'appl-btn' : 'px-4 !bg-[#dadada88] !text-black !font-bold'} rounded-md h-10 flex items-center justify-center`}
          {...rest}
        >
          {children}
        </button>
      )}
    </>
  );
};

export default Button;
