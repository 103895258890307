import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useEditBulkUploadMutation,
  useUpdateBulkUploadMutation,
} from "./bulkUploadApi";
import { useEditUploadState } from "./bulkUploadSlice";
import { Table, TableCell } from "../../ui/table";
import { Icon } from "../../ui/icons";
import { Input } from "../../ui/forms";
import { Button, Loader } from "../../ui";
import { showToaster } from "../../helperFunctions";

const EditFileBulkModal = (props: any) => {
  const editUploadState = useEditUploadState();
  const [updateBulkUpload] = useUpdateBulkUploadMutation();
  const { isOpen, onClose, editId } = props;
  const [error, setError] = useState<string>("");

  const { handleSubmit } = useForm({});
  const [editBulkUpload, { isLoading }] = useEditBulkUploadMutation();
  const [editSingleData, setEditSingleData] = useState<any>(false);
  const [isLoader, setIsLoader] = useState(false);

  const [editData, setEditData] = useState<any>({});
  const [payloadData, setPayloadData] = useState<any>({});
  const [isUpdated, setIsUpdated] = useState(false);

  const errorMessage = "Must be between 8 to 18 characters!";

  const onGetEditData: any = async () => {
    const payload = { id: editId };
    try {
      const res: any = await editBulkUpload(payload).unwrap();
      if (!res?.data?.length) {
        onHide();
      }
    } catch (err) {
      console.error("Error =-=>", err);
    }
  };

  useEffect(() => {
    if (isOpen) {
      onGetEditData();
    }
     // eslint-disable-next-line 
  }, [isOpen]);

  if (!isOpen) return null;

  const onSingleEditClick = (data: any) => {
    if (!editSingleData) {
      setEditData(data);
    } else {
      setEditData({});
    }
    setEditSingleData(!editSingleData);
    setIsUpdated(false);
    setPayloadData({
      ifsc: data?.IFSC_Code,
      account_number: data?.Account_Number,
    });
  };

  const onSubmit = async (values: any) => {
    try {
      setIsLoader(true);
      if (
        !payloadData?.account_number ||
        payloadData?.account_number?.length < 8 ||
        payloadData?.account_number?.length > 18
      ) {
        setError(errorMessage);
        setIsLoader(false);
        return;
      }
      setError("");
      let payload = {
        ...payloadData,
        id: editId,
        oldifsc: editData?.IFSC_Code,
        oldaccount_number: editData?.Account_Number,
      };

      const res: any = await updateBulkUpload(payload).unwrap();
      showToaster(res?.message);
      if (res?.status) {
        setIsUpdated(true);
        setEditSingleData(false);
        setIsLoader(false);
        setEditData({});
        onGetEditData();
      } else {
        setIsLoader(false);
        setIsUpdated(true);
        setEditData({});
        setEditSingleData(false);
      }
    } catch (err: any) {
      setIsUpdated(true);
      setEditSingleData(false);
      setEditData({});
      setIsLoader(false);
    }
  };

  const columns = [
    {
      title: "Account Number",
      name: "Account_Number",
    },
    { title: "Beneficiary Name", name: "Beneficiary_Name" },
    {
      title: "IFSC Code",
      name: "IFSC_Code",
    },
    { title: "Payment Ref ID", name: "Payment_Ref_ID" },
    { title: "Transfer Amount", name: "Transfer_Amount" },
    {
      title: "Action",
      Cell: (data: any) => {
        const isEnabled = data?.id === editData?.id;
        return (
          <div className="flex items-center">
            <div className="me-4 flex items-center justify-center bg-[#E9E9FF] rounded-full h-[32px] w-[32px] ">
              <Icon name="Edit" onClick={() => onSingleEditClick(data)} />
            </div>
            {!isLoader ? (
              <Button
                disabled={!isEnabled}
                type="submit"
              >
                Update
              </Button>
            ) : (
              <div className="flex item-center justify-center">
                <Loader />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const onHide = () => {
    onClose();
    setError("");
    setEditSingleData(false);
    setEditData({});
    setPayloadData({});
  };
  return (
    <form
      className="fixed inset-0 z-50 flex items-center justify-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          onHide();
        }}
      />

      <div className="modal-container bg-white w-10/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-4 text-left ">
          <div className="flex justify-between items-center pb-3 px-6">
            <p className="text-2xl font-bold">Bulk Edit</p>
            <div className="modal-close cursor-pointer z-50" onClick={onHide}>
              <svg
                className="fill-current text-black"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path d="M18 1.3L16.7 0 9 7.7 1.3 0 0 1.3 7.7 9 0 16.7 1.3 18 9 10.3l7.7 7.7 1.3-1.3L10.3 9z"></path>
              </svg>
            </div>
          </div>
          <Table
            columns={columns}
            isLoading={isLoading}
            isExpendable={false}
            data={editUploadState?.data}
          >
            {editUploadState?.data?.map((item: any) => {
              return (
                <>
                  <tr className="h-1 tr"></tr>
                  <tr className={`table-row tablecell-hoverx`}>
                    {columns?.map((column) => {
                      const { Cell, name, type }: any = column;
                      const filterId = item?.id === editData?.id;
                      if (name === "IFSC_Code") {
                        return (
                          <>
                            {filterId && editSingleData && !isUpdated ? (
                              <td>
                                <Input
                                  className="w-9/12"
                                  name={type}
                                  value={
                                    payloadData
                                      ? payloadData?.ifsc
                                      : item?.IFSC_Code
                                  }
                                  onChange={(e) => {
                                    setPayloadData({
                                      ...payloadData,
                                      ifsc: e.target.value,
                                    });
                                  }}
                                />
                              </td>
                            ) : (
                              <td>{item?.IFSC_Code}</td>
                            )}
                          </>
                        );
                      }

                      if (name === "Account_Number") {
                        return (
                          <>
                            {editSingleData &&
                            filterId &&
                            !isUpdated &&
                            (item?.Account_Number?.length > 8 ||
                              item?.Account_Number?.length < 19) ? (
                              <td>
                                <Input
                                  type="number"
                                  className="w-9/12"
                                  name={type}
                                  value={
                                    payloadData
                                      ? payloadData?.account_number
                                      : item?.Account_Number
                                  }
                                  onChange={(e) => {
                                    const length = e.target.value?.length;

                                    if (length >= 8 && length <= 18) {
                                      setError("");
                                    } else {
                                      setError(errorMessage);
                                    }
                                    setPayloadData({
                                      ...payloadData,
                                      account_number: e.target.value?.slice(
                                        0,
                                        18
                                      ),
                                    });
                                  }}
                                />
                                <div className="text-red-500">{error}</div>
                              </td>
                            ) : (
                              <td>{item?.Account_Number}</td>
                            )}
                          </>
                        );
                      }

                      return (
                        <TableCell>
                          {Cell ? (
                            <Cell {...item} />
                          ) : (
                            `${type && item?.[name] ? type : ""}${
                              item?.[name] ? item?.[name]?.toString() : " - "
                            }`
                          )}
                        </TableCell>
                      );
                    })}
                  </tr>
                  <tr className="h-1 tr"></tr>
                </>
              );
            })}
          </Table>
          <div className="flex justify-end pt-2 px-6">
            {/* <button
              className="px-4 bg-transparent p-3 rounded-lg text-indigo-500 hover:bg-gray-100 hover:text-indigo-400 mr-2"
              onClick={onClose}
            >
              Close
            </button> */}
            {/* <button
              type="submit"
              className="px-4 bg-indigo-500 p-3 rounded-lg text-white hover:bg-indigo-400"
            >
              Save
            </button> */}
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditFileBulkModal;
