import { useState } from "react";

// UI IMPORT
import { Icon } from "../icons";

interface PasswordProps {
  className?: string;
  inputClassName?: string;
  name: string;
  label?: string;
  value?: any;
  onChange?: () => void;
  placeholder?: string;
  errors?: any;
  register?: any;
  disabled?: boolean;
}

const Password = (props: PasswordProps) => {
  const {
    className = "",
    inputClassName = "",
    label,
    errors,
    register,
    name,
    ...rest
  } = props;

  const getRegister = (register: any) =>
    register && name ? { ...register?.(name) } : {};

  const message = errors?.[name]?.message || "";

  const [show, setShow] = useState(false);
  return (
    <div className={`${className} w-fit flex flex-col justify-center relative`}>
      {label ? <label className="text-ev-darkblue text-sm">{label}</label> : null}
      <div className="flex flex-col justify-center">
        <Icon className="absolute me-4 text-xl ms-4" name="LockIcon" />
        <input
          name={name}
          type={show ? "text" : "password"}
          className={`${inputClassName} text-sm pl-11 pr-11 ${
            message ? "border-red-500" : ""
          } w-full text-base h-10 rounded-md outline-none border-2 focus:border-chatlook-darkblue`}
          {...getRegister(register)}
          {...rest}
        />
        <Icon
          className="absolute right-0 me-4 text-xl"
          name={show ? "EyeOn" : "EyeOff"}
          onClick={() => {
            setShow(!show);
          }}
        />
      </div>
      {message ? <span className="text-red-500 text-sm">{message}</span> : null}
    </div>
  );
};

export default Password;
