import {  useState } from "react";
import { useUploadBulkUploadMutation } from "./bulkUploadApi";
import { useForm } from "react-hook-form";
import { Button, Icon, Loader } from "../../ui";

const BulkUploadModal = (props: any) => {
  const [fileUpload, setFileUpload] = useState<any>({});
  const [uploadBulkUploading] = useUploadBulkUploadMutation();
  const { handleSubmit } = useForm({});
  const { isOpen, onClose, setShowMsg } = props;
  const [isLoader, setIsLoader] = useState(false);

  const onHide = () => {
    setFileUpload({});
    onClose();
  };
  const onSubmit = async (e: any) => {
    setIsLoader(true);
    const bulkfileUpload = new FormData();
    bulkfileUpload.append("bulkfileUpload", fileUpload);
    try {
      const res: any = await uploadBulkUploading(bulkfileUpload).unwrap();
       // eslint-disable-next-line 
      if (res?.status == true) {
        setShowMsg(true);
        setIsLoader(false);

        onHide();
      } else {
        setIsLoader(false);
        onHide();
      }
    } catch (err: any) {
      setIsLoader(false);
      onHide();
    }
  };


  if (!isOpen) return null;

  return (
    <form
      className="fixed inset-0 z-50 flex items-center justify-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          onHide();
        }}
      />

      <div className="modal-container bg-white w-5/12 md:w-8/12 sm:w-10/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-6 text-left px-6">
          <div className="pb-3 text-center">
            <p className="text-2xl font-bold text-ev-darkblue pb-2">
              Upload CSV File
            </p>
            <p className="text-ev-lightblue text-sm">
              Upload and attach files to this Option.
            </p>
          </div>

          <div className="flex flex-col justify-center items-center drag-file-area rounded-lg ">
            <div className="text-center py-4 px-5 mt-2.5 mb-3.5">
              <div>
                <Icon name="UploadIcon" className="m-auto" />
              </div>
              <label className="label">
                <span className="browse-files">
                  <input
                    id="myfile"
                    accept=".csv"
                    type="file"
                    className="opacity-0"
                    onChange={(e: any) => setFileUpload(e.target.files[0])}
                  />
                  <div className="browse-files-text text-chatlook-darkblue text-[18px] ">
                    Click to upload CSV File
                  </div>
                  <div>{fileUpload ? fileUpload?.name : null}</div>
                </span>
              </label>
            </div>
          </div>
          <div className="flex justify-end pt-2 items-center gap-3">
            <Button
              // className="px-4 bg-transparent p-3 rounded-lg text-indigo-500 hover:bg-gray-100 hover:text-indigo-400 mr-2"
              onClick={onHide}
            >
              Close
            </Button>
            {!isLoader ? (
              <Button 
              type="submit"
              // className="px-4 bg-indigo-500 p-3 rounded-lg text-white hover:bg-indigo-400"
              >
                Save
              </Button>
            ) : (
              <div className="flex item-center justify-center">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default BulkUploadModal;
