import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { reportApi } from "./reportApi";

interface ReportState {
  ReportDataState: any;
  DownloadReport: any;
}

const initialState: ReportState = {
  ReportDataState: {},
  DownloadReport: {},
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    clearReport: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      reportApi.endpoints.getReport.matchFulfilled,
      (state, { payload }) => {
        state.ReportDataState = payload || {};
      }
    );
    builder.addMatcher(
      reportApi.endpoints.downlaodReport.matchFulfilled,
      (state, { payload }) => {
        state.DownloadReport = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default reportSlice.reducer;
export const { clearReport } = reportSlice.actions;

export const selectReportData = (state: RootState) =>
  state.report.ReportDataState;
export const useReportData = () => {
  const reportData = useSelector(selectReportData);
  return useMemo(() => reportData, [reportData]);
};
