import { InputTypes } from "../theme";
import { Icon, IconKeys } from "../icons";

interface InputProps {
  className?: string;
  inputClassName?: string;
  lableClassName?: string;
  name: string;
  label?: string;
  value?: any;
  onChange?: (e?: any) => void;
  type?: InputTypes;
  icon?: IconKeys;
  rightIcon?: IconKeys;
  placeholder?: string;
  errors?: any;
  register?: any;
  disabled?: boolean;
  min?: number;
  max?: number;
}

const Input = (props: InputProps) => {
  const {
    className = "",
    inputClassName = "",
    label,
    icon,
    rightIcon,
    errors,
    register,
    name,
    min,
    max,
    lableClassName = "",
    ...rest
  } = props;

  const getRegister = (register: any) =>
    register && name ? { ...register?.(name) } : {};

  const message = errors?.[name]?.message || "";
  return (
    <div className={`${className} w-fit flex flex-col justify-center relative`}>
      {label ? (
        <label className={`${lableClassName} text-sm text-ev-darkblue`}>{label}</label>
      ) : null}
      <div className="flex flex-col justify-center">
        {icon ? (
          <Icon className="absolute me-4 text-xl ms-4" name={icon} />
        ) : null}
        <input
          minLength={min}
          maxLength={max}
          name={name}
          className={`${inputClassName} text-sm ${icon ? "pl-11" : "pl-3"} ${
            rightIcon ? "pr-11" : "pr-3"
          } ${
            message ? "border-red-500" : ""
          } w-full text-base h-10 rounded-md  outline-none border-2 focus:border-chatlook-darkblue`}
          {...getRegister(register)}
          {...rest}
        />
        {rightIcon ? (
          <Icon className="absolute right-0 me-4 text-xl" name={rightIcon} />
        ) : null}
      </div>
      {message ? <span className="text-red-500 text-sm">{message}</span> : null}
    </div>
  );
};

export default Input;
