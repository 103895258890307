import { useMemo } from 'react'

// PROJECT IMPORT
import { RootState } from '../../utills/store'
import {balanceSheetApi} from './balanceSheetApi'

// THIRD - PARTY IMPORT
import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

interface BalncesheetState {
    summaryData:any;
    chartsummaryItems:any;
}

const initialState:BalncesheetState = {
    summaryData: {},
    chartsummaryItems:{}
}

export const balanceSheetSlice = createSlice({
    name: 'balancesheet',
    initialState,
    reducers: {
        clearBalanceSheet:() => initialState

    },
    extraReducers:(builder) => {
        builder.addMatcher(balanceSheetApi.endpoints.getBalnceSummary.matchFulfilled,(state,{payload}) => {
            state.chartsummaryItems = payload || {}
        })
    }
})

// Action creators are generated for each case reducer function
export default balanceSheetSlice.reducer
export const {clearBalanceSheet} = balanceSheetSlice.actions

export const selectBalanceSummaryItems = (state:RootState) => state.balanceSheet.chartsummaryItems
export const useBalanceSummaryItems = () => {
    const chartSummaryItems = useSelector(selectBalanceSummaryItems)
    return useMemo(() => chartSummaryItems, [chartSummaryItems])
}