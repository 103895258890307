import { AiFillCopy } from "react-icons/ai";
import { Button } from "../../ui";
import { useResendWebhookMutation } from "./withdrawalApi";

const WithdrawModel = (props: any) => {
  const { isOpen, onClose, data } = props;
  const [getresendWebhook] = useResendWebhookMutation();

  // const textToCopy = {text};
  const copyToClipboard = (text: any) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const payload = {
        withdrawal_id: data.withdrawal_id,
      };
      await getresendWebhook(payload).unwrap()
      onClose();
    } catch (err) { }
  };

  if (!isOpen) return null;
  return (
    <form
      className="fixed inset-0 z-50 flex items-center justify-center"
      onSubmit={onSubmit}
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          onClose();
        }}
      />

      <div className="modal-container bg-white min-w-[400px] md:max-w-md mx-auto rounded-xl shadow-lg z-50 overflow-y-auto">
        <div className="modal-content py-4 text-left ">
          <div className="flex justify-between items-center pb-3 mx-6 border-b-2">
            <p className="text-xl font-bold text-ev-darkblue">
              Withdraw Details
            </p>
            <div className="modal-close cursor-pointer z-50" onClick={onClose}>
              <svg
                className="fill-current text-black"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path d="M18 1.3L16.7 0 9 7.7 1.3 0 0 1.3 7.7 9 0 16.7 1.3 18 9 10.3l7.7 7.7 1.3-1.3L10.3 9z"></path>
              </svg>
            </div>
          </div>
          <div className="px-6 pt-3">
            <div className="my-2">
              {/* <h3 className="text-sm font-bold mb-2 text-[#6f6af8]">
                Transcation detail
              </h3> */}

              <div className=" main-table table-custome">
                <table className="w-full">
                  <tbody className="text-ev-lightblue border-1 text-sm">
                    <tr>
                      <td>Withdraw ID:</td>
                      <td>{data?.withdrawal_id}</td>
                      <td>
                        <AiFillCopy
                          onClick={() => copyToClipboard(data?.withdrawal_id)}
                          className="cursor-pointer"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Order ID:</td>
                      <td>{data?.order_id}</td>
                      <td>
                        <AiFillCopy
                          onClick={() => copyToClipboard(data?.order_id)}
                          className="cursor-pointer"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>UTR:</td>
                      <td>{data?.utr || "-"}</td>
                      <td>
                        <AiFillCopy
                          onClick={() => copyToClipboard(data?.utr)}
                          className="cursor-pointer"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Success:</td>
                      <td>{data?.SuccessDate}</td>
                      <td>
                        <AiFillCopy
                          onClick={() => copyToClipboard(data?.utr)}
                          className="cursor-pointer"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="my-2">
              <h3 className="text-sm font-bold mb-2 text-[#6f6af8]">
                Account Details
              </h3>
              <div className=" main-table table-custome">
                <table className="w-full text-sm">
                  <tbody className="text-ev-lightblue  border-1">
                    <tr>
                      <td>Name :</td>
                      <td>{data?.acname}</td>
                    </tr>
                    <tr>
                      <td>A/C :</td>
                      <td>{data?.acnumber}</td>
                    </tr>
                    <tr>
                      <td>IFSC :</td>
                      <td>{data?.ifsc}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="my-2">
              <h3 className="text-sm font-bold mb-2 text-[#6f6af8]">Amount</h3>

              <div className=" main-table table-custome">
                <table className="w-full text-sm">
                  <tbody className="text-ev-lightblue  border-1">
                    <tr>
                      <td>PG Fees:</td>
                      <td>₹ {data?.pg_fees}</td>
                    </tr>
                    <tr>
                      <td>Settled:</td>
                      <td>₹ {data?.withdrawal_amount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-center mt-4">
              {/* <Button
                type="submit"
                className="!text-sm"
              onClick={}
              >
                Resend Webhook
              </Button> */}
              <Button type="submit" className="!text-sm">
                Resend Webhook
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default WithdrawModel;
