import { api } from "../../utills/api";
import { LoginFormTypes } from "./types";

export const authApi = api.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<any, LoginFormTypes>({
            query: (params) => ({
                url: "/login",
                body: params,
                method: "POST",
            }),
        }),
        getLogo: build.mutation<any, { domain: string }>({
            query: (params) => ({
                url: "/onload",
                body: params,
                method: "POST",
            }),
        }),
    }),
});

export const { useLoginMutation, useGetLogoMutation } = authApi;
export const {
    endpoints: { login },
} = authApi;
