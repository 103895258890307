import { useState, useEffect, Fragment } from "react";

// UI IMPORT
import { Button, Input, Loader, Table, TableRow } from "../../ui";

// PROJECT IMPORT
import {
  useCheckClaimDataMutation,
  useGetUnClaimutrDataQuery,
} from "./claimutrApi";
import ClaimutrModel from "./ClaimutrModel";
import { useUnClaimutrData } from "./claimutrSlice";

// THIRD - PARTY IMPORT
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const ClaimUtr = () => {
  const [isClaimModal, setIsClaimModal] = useState(false);
  const [isUtr, setIsUtr] = useState({});
  const [isLoader, setIsLoader] = useState(false);

  const unClaimUtrData = useUnClaimutrData();

  const [checkClaimData] = useCheckClaimDataMutation();
  const { isLoading } = useGetUnClaimutrDataQuery(undefined, {
    refetchOnFocus: true,
  });

  const schema = yup.object({
    utr: yup.string().required("Please Enter UTR"),
  });

  const {
    getValues,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const value = getValues();

  const columns = [
    {
      title: "Date",
      name: "created_at_ist",
    },
    {
      title: "Amount",
      name: "amount",
    },
    {
      title: "UTR",
      name: "payment_utr",
    },
    {
      title: "Description",
      name: "description",
    },
    {
      title: "Actions",
      name: "actions",
      Cell: (item: any) => {
        return (
          <>
            <Button
              className="!h-9 !text-sm"
              onClick={async () => {
                setValue("utr", item?.payment_utr);
                onSubmit();
              }}
            >
              Claim
            </Button>
          </>
        );
      },
    },
  ];
  const onSubmit = async () => {
    try {
      setIsLoader(true);

      const values: any = getValues();
      const res: any = await checkClaimData(values).unwrap();

      if (res?.status) {
        if (!res?.data?.status) {
          setIsUtr({
            ...isUtr,
            is: true,
            amount: res?.data?.amount,
            traId: values?.transaction_id
              ? values?.transaction_id
              : res?.data?.deposit_id,
          });
          setIsClaimModal(true);
        } else {
          setIsUtr({
            ...isUtr,
            is: false,
            amount: res?.data?.amount,
            traId: values?.transaction_id,
          });
          setIsClaimModal(true);
        }
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    } catch (e) {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    if (!isClaimModal) {
      reset();
    }
    // eslint-disable-next-line
  }, [isClaimModal]);

  return (
    <div className="space-y-5">
      <form
        className="flex flex-wrap gap-2 items-start"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* <Input
          className="me-2"
          name="deposit_id"
          placeholder="Enter Deposit ID"
          label="Search"
          register={register}
        /> */}
        <Input
          className="me-2"
          name="utr"
          placeholder="Enter UTR Number"
          label="UTR"
          register={register}
          errors={errors}
        />
        {/* <Input
          className="me-2"
          name="amount"
          placeholder="Enter Amount"
          label="Amount"
          register={register}
        /> */}

        {!isLoader ? (
          <Button type="submit" className="mt-[22px] !h-9 !text-sm">
            Check
          </Button>
        ) : (
          <div className="flex item-center justify-center mt-6 ">
            <Loader />
          </div>
        )}
      </form>
      <div className="">
        <h4 className="py-2 font-bold text-chatlook-darkblue">Unclaim UTR</h4>
        <Table
          isLoading={isLoading}
          isExpendable={false}
          data={unClaimUtrData}
          columns={columns}
        >
          {unClaimUtrData?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow columns={columns} isExpendable={false} item={item} />
              </Fragment>
            );
          })}
        </Table>
        {/* <Icon name="ClaimUtrHero" className="m-auto opacity-70" /> */}
      </div>

      <ClaimutrModel
        isOpen={isClaimModal}
        onClose={() => setIsClaimModal(false)}
        values={value}
        isUtr={isUtr}
      />
    </div>
  );
};

export default ClaimUtr;
