import { api } from "../../utills/api";

export const reportApi = api.injectEndpoints({
  endpoints: (build) => ({
    getReport: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/report/getReport",
        body: params,
        method: "POST",
      }),
    }),
    addReport: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/report/addReport",
        body: params,
        method: "POST",
      }),
    }),
    downlaodReport: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/report/download",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetReportMutation,
  useAddReportMutation,
  useDownlaodReportMutation,
} = reportApi;
export const {
  endpoints: { getReport, addReport, downlaodReport },
} = reportApi;
