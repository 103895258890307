import { useEffect, useState, Fragment } from "react";

// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  DatePicker,
  Loader,
  Table,
  TableRow,
} from "../../ui";

// PROJECT IMPORT
import { useGetBalnceSummaryMutation } from "../balanceSheet/balanceSheetApi";
import { useBalanceSummaryItems } from "../balanceSheet/balanceSheetSlice";
import {
  useGetDepositChartMutation,
  useGetSummaryQuery,
  useGetWithdrawalChartMutation,
} from "./dashboardApi";
import {
  useDepositChart,
  useSummaryItems,
  useWithdrawalChart,
} from "./dashboardSlice";
import { useUser } from "../auth/authSlice";
import { FILTER } from "../../constant";
import { summaryData, transactionSummaryData } from "./mock";
import { Card } from "../common";
import Chart from "./Chart";
import { useForm } from "react-hook-form";
import { dateFormatter } from "../../helperFunctions";

const Dashboard = () => {
  const balanceSummaryItems = useBalanceSummaryItems();
  const summarytData = useSummaryItems();
  const user = useUser();
  const depositChart = useDepositChart();
  const withdrawalChart = useWithdrawalChart();

  const data = user?.sidebar?.find(
    (item: any) => item?.name === "Balance Sheet"
  );
  const [filter, setFilter] = useState(FILTER);

  const [getBalnceChartSummary, { isLoading }] = useGetBalnceSummaryMutation();
  const [getDepositChart, { isLoading: depositIsLoading }] =
    useGetDepositChartMutation();
  const [getWithdrawalChart, { isLoading: withdrawalIsLoading }] =
    useGetWithdrawalChartMutation();
  useGetSummaryQuery();

  const onGetData = () => {
    const payload: any = { ...filter };
    delete payload["isFilter"];
    getBalnceChartSummary(payload);
  };

  const onGetChartData = () => {
    const values = getValues();
    if (values?.date?.length !== 2) {
      return;
    }
    const payload = {
      merchantId: user?.userData?.merchant_id,
      startDate: dateFormatter(values?.date?.[0], "start"),
      endDate: dateFormatter(values?.date?.[1], "end"),
    };
    getDepositChart(payload);
    getWithdrawalChart(payload);
  };

  useEffect(() => {
    if (data) {
      onGetData();
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    onGetChartData();
  }, [user]);

  const columns = [
    { title: "Date", name: "payDate" },
    { title: "Deposit", name: "deposit", type: "₹ " },
    { title: "Withdrawal", name: "withdrawal", type: "₹ " },
    { title: "Un Settled", name: "unSettled", type: "₹ " },
    { title: "Open Balance", name: "openAmt", type: "₹ " },
    { title: "Closing Balance", name: "closingAmt", type: "₹ " },
    { title: "Last Update", name: "last_updated_at" },
  ];

  const depositOptions = {
    responsive: true,
    scales: {
      tickColor: "red",
    },
    plugins: {
      legend: {
        position: "bottom" as const,
        align: "center",
      },
      title: {
        display: false,
        text: "Deposit Chart",
        align: "start",
        font: {
          size: 16,
        },
        padding: {
          bottom: 15,
          left: 10,
          top: 20,
        },
      },
    },
  };

  const withdrawalOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
        align: "center",
      },
      title: {
        display: false,
        text: "Withdrawal Chart",
        align: "start",
        font: {
          size: 16,
        },
        padding: {
          bottom: 15,
          left: 10,
          top: 20,
        },
      },
    },
  };

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      date: [new Date(), new Date()],
    },
  });

  const onSubmit = () => {
    onGetChartData();
  };
  return (
    <div className="space-y-2">
      <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-2 gap-4">
        {summaryData.map((item: any, index) => (
          <Fragment key={index}>
            <Card
              index={index}
              value={+summarytData?.[item.key]}
              text={item?.text}
              icon={item?.img}
            />
          </Fragment>
        ))}
      </div>

      <h2 className="text-ev-darkblue table-heading !text-lg">
        Today's Deposit Summery
      </h2>
      <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-2 gap-4">
        {transactionSummaryData.map((item: any, index) => {
          const data = summarytData?.DEPOSIT?.find(
            (val: any) => val?.name === item?.text
          );
          return (
            <Fragment key={index}>
              <Card
                index={index}
                value={+data?.totalAmt}
                text={item?.text}
                icon={item?.img}
                count={data?.txnCount}
                percentage={data?.txnCountPer}
              />
            </Fragment>
          );
        })}
      </div>
      <div className="bg-white rounded-md space-y-2 p-2">
        <form
          className="flex justify-between gap-2 items-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <span className="font-bold text-gray-600 pl-2">Deposit Chart</span>
          <div className="flex items-center gap-2">
            <ControlledDatePicker
              name="date"
              placeholder="Enter Date"
              options={{
                mode: "range",
              }}
              control={control}
            />
            <Button type="submit">Apply</Button>
          </div>
        </form>
        <div className="divide-y divide-[#00000020] space-y-2">
          {depositIsLoading || withdrawalIsLoading ? (
            <div className="w-full flex items-center justify-center h-[600px]">
              <Loader />
            </div>
          ) : (
            <>
              <Chart
                data={depositChart}
                options={depositOptions}
                isLoading={depositIsLoading}
              />
              <Chart
                data={withdrawalChart}
                options={withdrawalOptions}
                label="Withdrawal Chart"
                isLoading={withdrawalIsLoading}
              />
            </>
          )}
        </div>
      </div>

      {data ? (
        <div className="App">
          <h2 className="text-ev-darkblue table-heading">
            Recent Balance Sheet
          </h2>
          <Table
            columns={columns}
            isLoading={isLoading}
            data={balanceSummaryItems?.data || []}
            count={balanceSummaryItems?.total_item}
            pagination={filter}
            isExpendable={false}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
              });
            }}
          >
            {balanceSummaryItems?.data?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    isExpendable={false}
                    item={item}
                  />
                </Fragment>
              );
            })}
          </Table>
        </div>
      ) : null}
    </div>
  );
};

export default Dashboard;
