import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utills/store";
import { withdrawalApi } from "./withdrawalApi";

interface WithdrawalState {
  withdrawalData: any;
}

const initialState: WithdrawalState = {
  withdrawalData: {},
};

export const withdrawalSlice = createSlice({
  name: "withdrawal",
  initialState,
  reducers: {
    clearWithdrawal:() => initialState

  },
  extraReducers: (builder) => {
    builder.addMatcher(
      withdrawalApi.endpoints.getWithdrawal.matchFulfilled,
      (state, { payload }) => {
        state.withdrawalData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default withdrawalSlice.reducer;
export const {clearWithdrawal} = withdrawalSlice.actions

export const selectWithdrawaleData = (state: RootState) =>
  state.withdrawal.withdrawalData;
export const useWithdrawalData = () => {
  const withData = useSelector(selectWithdrawaleData);
  return useMemo(() => withData, [withData]);
};
