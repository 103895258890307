import { Link, useLocation } from "react-router-dom";
import { Icon } from "../../ui";
import { useIsDrawerOpen } from "../common/commonSlice";

const Navigator = (props: any) => {
  const { name, path, navIcon, onClick } = props;

  const location = useLocation();
  const isDrawerOpen = useIsDrawerOpen();


  if (!Array.isArray(props.children) && props.children) {
    var { subName, subPath } = props.children.props;
  }

  return (
    <ul className="mainMenu" key={name}>
      <li onClick={onClick}>
        <div title={name}>
          <Link
            to={{ pathname: path }}
            className={`${location.pathname === path && "activeMenu"} ${
              isDrawerOpen ? "justify-between" : "justify-center"
            }`}
          >
            <div className={`flex ${isDrawerOpen ? "py-1.5 px-5" : "py-2.5"}`}>
              <Icon
                name={navIcon}
                className={`${isDrawerOpen ? "me-4" : ""}  `}
              />
              {isDrawerOpen ? (
                <span className="text-capitalize text-base font-medium">
                  {name}
                </span>
              ) : null}
            </div>
            {props?.children && <i className="fa-solid fa-angle-right"></i>}
          </Link>
        </div>
        {/* If Submenu */}
        {props.children && (
          <>
            {Array.isArray(props.children) ? (
              <ul className={`subMenu transform0`}>
                {props.children?.map((res: any) => {
                  const { subName, subPath, onClick } = res?.props;
                  return (
                    <>
                      <div title={subName}>
                        <li onClick={onClick}>
                          <Link
                            to={{ pathname: subPath }}
                            className={`${
                              location.pathname === subPath && "activeMenu"
                            }`}
                          >
                            <i className="fa-solid fa-circle"></i>
                            <span>{subName}</span>
                          </Link>
                        </li>
                      </div>
                    </>
                  );
                })}
              </ul>
            ) : (
              <ul className={`subMenu transform0`}>
                <li>
                  <Link
                    to={{ pathname: subPath }}
                    className={`${
                      location.pathname === subPath && "activeMenu"
                    }`}
                  >
                    <i className="fa-solid fa-circle"></i>
                    <span className="text-capitalize">{subName}</span>
                  </Link>
                </li>
              </ul>
            )}
          </>
        )}
      </li>
    </ul>
  );
};

export default Navigator;
