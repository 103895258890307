import { api } from "../../utills/api";

export const fundTranferApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInternalFundTransfer: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/internalfundtransfer",
        body: params,
        method: "POST",
      }),
    }),
  }),
});
export const {
  useGetInternalFundTransferMutation,
} = fundTranferApi;

export const {
  endpoints: {
    getInternalFundTransfer,
  },
} = fundTranferApi;
