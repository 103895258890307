export const depodataSummary = [
  {
    imgSrc: "DashboardDeposite",
    key: "total_deposite",
    text: "Total No of Deposit ",
    rupee:false
  },
  {
    imgSrc: "DashboardWithdrawal",
    key: "total_deposit_amount",
    text: "Total Deposit Amount",
  },
  {
    imgSrc: "DashboardUnsettled",
    key: "total_fees",
    text: "Total Fees",
  },
  {
    imgSrc: "DashboardActiveBalance",
    key: "total_payable_amount",
    text: "Total Payable Amount",
  },
];

export const filterOptions = [
  { value: "UTR", label: "UTR" },
  { value: "CustEmail", label: "Customer Email" },
  { value: "CustMobile", label: "Customer Mobile" },
  { value: "OrdID", label: "Order ID" },
  { value: "PayAmt", label: "Deposit Amount" },
  { value: "DepositId", label: "Deposit ID" },
];
