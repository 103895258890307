import { api } from "../../utills/api";

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/user/get",
        body: params,
        method: "POST",
      }),
    }),
    addUsers: build.mutation<any, any>({
      query: (params) => ({
        url: "/user/add",
        body: params,
        method: "POST",
      }),
    }),
    updateUsers: build.mutation<any, any>({
      query: (params) => ({
        url: "/user/update",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetUsersMutation,
  useAddUsersMutation,
  useUpdateUsersMutation,
} = usersApi;
export const {
  endpoints: { getUsers, addUsers, updateUsers },
} = usersApi;
