import { useEffect, useState, Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  Icon,
  Input,
  Select,
  Table,
  TableRow,
  getStatusTypes,
} from "../../ui";

// PROJECT IMPORT
import { filterOptions, withdrawalSummary } from "./mock";
import {
  useGetWithdrawalMutation,
  useResendWebhookMutation,
} from "./withdrawalApi";
import { useWithdrawalData } from "./withdrawalSlice";
import { FILTER } from "../../constant";
import { statusOptions } from "../common/mock";
import WithdrawModel from "./WithdrawModel";
import { Card } from "../common";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import WithdrawalAddModel from "./WithdrawalAddModel";
import { AiFillCopy } from "react-icons/ai";
import { dateFormatter } from "../../helperFunctions";

const Withdrawal = () => {
  const [getWithdrawal, { isLoading }] = useGetWithdrawalMutation();
  const withdrawalData = useWithdrawalData();

  const [isEditModalBulkOpen, setIsEditModalBulkOpen] = useState(false);
  const [isAddModalBulkOpen, setIsAddModalBulkOpen] = useState(false);

  const [currentData, setCurrentData] = useState({});
  const [filter, setFilter] = useState(FILTER);
  const [isError, setIsError] = useState<any>();
  const [refresh, setRefresh] = useState<boolean>(false);

  const [getresendWithdrawWebhook] = useResendWebhookMutation();

  const handleButtonClick = async (item: any) => {
    try {
      const payload = {
        withdrawal_id: item?.withdrawal_id,
      };
      await getresendWithdrawWebhook(payload).unwrap();
    } catch (err) {}
  };

  const columns = [
    { title: "Date", name: "created_at" },
    { title: "Withdrawal ID", name: "withdrawal_id" },
    { title: "Type", name: "withdrawal_type" },
    {
      title: "Status",
      name: "payoutSts",
      Cell: (data: any) => {
        return (
          <span className={`${getStatusTypes(data?.withdrawalSts)}`}>
            {data?.withdrawalSts}
          </span>
        );
      },
    },
    { title: "Amount", name: "withdrawal_amount" , type: "₹ " },
    { title: "UTR", name: "utr" },
  ];

  const { register, handleSubmit, reset, getValues, control } = useForm({});

  const onGetData = () => {
    const values = getValues();
    let payload: any = {};
    if (filter?.isFilter) {
      payload = {
        ...filter,
        filter_data: {
          InitiateDate:dateFormatter(values?.date?.[0],'start'),
          FinalizeDate:dateFormatter(values?.date?.[1],'end'),
          status: values.status,
          [values.transaction_id]: values?.search,
        },
      };
    } else {
      payload = { ...filter };
    }
    delete payload["isFilter"];
    getWithdrawal(payload);
  };

  const onSubmit = () => {
    setFilter({ ...FILTER, isFilter: true });
  };

  useEffect(() => {
    if (
      (isError?.filter && isError?.value) ||
      (isError?.value == "" && isError?.value == "") ||
      isError?.value == undefined
    ) {
      onGetData();
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    const IntervalClick = setInterval(() => {
      if (refresh) {
        setFilter({ ...filter });
      }
    }, 9000);
    return () => clearInterval(IntervalClick);
  }, [refresh]);

  return (
    <>
      <form
        className={
          !isError?.filter && isError?.value
            ? "flex flex-wrap gap-2 px-1"
            : "flex flex-wrap gap-2 items-start px-1"
        }
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <div className="flex flex-wrap gap-2">
            <Select
              name="transaction_id"
              label="Filter"
              options={filterOptions}
              register={register}
              onChange={(e) =>
                setIsError({ ...isError, filter: e.target.value })
              }
            />
            <Input
              className="me-2"
              name="search"
              placeholder="Enter Search Value"
              label="Search"
              register={register}
              onChange={(e) =>
                setIsError({ ...isError, value: e.target.value })
              }
            />
          </div>
          {!isError?.filter && isError?.value ? (
            <div className="text-red-500 mt-1">*Please Select Filter</div>
          ) : null}
        </div>
        <Select
          name="status"
          label="Status"
          options={statusOptions}
          register={register}
        />

        <ControlledDatePicker
          name="date"
          label="Date"
          placeholder="Date"
          options={{
            mode: "range",
          }}
          control={control}
        />
        <Button type="submit" className="mt-[22px] !h-9 !text-sm">
          Apply
        </Button>
        <Button
          variant="Transaparent"
          className="mt-[22px] !h-9 !text-sm"
          onClick={() => {
            reset();
            setFilter({ ...FILTER });
          }}
        >
          Clear
        </Button>
        <Button
          type="button"
          variant={refresh ? "Primary" : "Transaparent"}
          className={`mt-[22px] !text-sm !h-9 ${
            refresh ? "hover:bg-chatlook-darkblue" : "hover:bg-white"
          }`}
          title="Auto Refresh"
          onClick={() => setRefresh(!refresh)}
        >
          <Icon
            name="Refresh"
            className={` ${refresh ? "animate-spin" : ""}`}
          />
        </Button>
      </form>

      <div className="mt-2">
        <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-2 gap-4">
          {withdrawalSummary?.map((item: any, index) => (
            <Fragment key={index}>
              <Card
                index={index}
                value={+withdrawalData?.summary?.[item.key]}
                text={item?.text}
                icon={item?.imgSrc}
                rupee={item?.rupee}
              />
            </Fragment>
          ))}
        </div>
        <div className="App mt-3">
          <Table
            columns={columns}
            isLoading={isLoading}
            data={withdrawalData?.data}
            count={withdrawalData?.total_item}
            pagination={filter}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
              });
            }}
          >
            {withdrawalData?.data?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow
                    columns={columns}
                    item={item}
                    // onClick={(item) => {
                    //   setCurrentData(item);
                    //   setIsEditModalBulkOpen(true);
                    // }}
                  >
                    <div className="flex justify-around py-2 ">
                      <div>
                        <h3 className="text-sm  mb-2 text-chatlook-darkblue">
                          Transcation detail
                        </h3>
                        <div className="bg-chatlook-white py-4 px-7 rounded-md text-sm">
                          <div className="flex justify-between items-center mb-3">
                            <div className="flex">
                              <p className="font-medium text-[#747070]">
                                Withdraw ID :{" "}
                              </p>
                              <p className="ps-2">{item?.withdrawal_id}</p>
                            </div>
                            <p className="ps-4">
                              <CopyToClipboard text={item?.withdrawal_id}>
                                <AiFillCopy className="cursor-pointer" />
                              </CopyToClipboard>
                            </p>
                          </div>
                          <div className="flex justify-between items-center mb-3">
                            <div className="flex">
                              <p className="font-medium text-[#747070]">
                                UTR :{" "}
                              </p>
                              <p className="ps-2">{item?.utr}</p>
                            </div>
                            <p className="ps-4">
                              <CopyToClipboard text={item?.utr}>
                                <AiFillCopy className="cursor-pointer" />
                              </CopyToClipboard>
                            </p>
                          </div>
                          <div className="flex justify-between items-center ">
                            <div className="flex">
                              <p className="font-medium text-[#747070]">
                                Order :{" "}
                              </p>
                              <p className="ps-2">{item?.order_id}</p>
                            </div>
                            <p className="ps-4">
                              <CopyToClipboard text={item?.order_id}>
                                <AiFillCopy className="cursor-pointer" />
                              </CopyToClipboard>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="text-sm">
                        <div className="mb-2.5">
                          <h5 className="font-medium text-[#747070]">Name</h5>
                          <p>{item?.acname}</p>
                        </div>
                        <div className=" mb-2.5">
                          <h5 className="font-medium text-[#747070]">A/C No</h5>
                          <p>{item?.acnumber || "-"}</p>
                        </div>
                        <div>
                          <h5 className="font-medium text-[#747070]">IFSC</h5>
                          <p>{item?.ifsc || "-"}</p>
                        </div>
                      </div>
                      <div className="text-sm">
                        <div className="mb-2.5">
                          <h5 className="font-medium text-[#747070]">
                            PG Fees
                          </h5>
                          <p>₹ {item?.pg_fees}</p>
                        </div>
                        <div className=" mb-2.5">
                          <h5 className="font-medium text-[#747070]">
                            Settled{" "}
                          </h5>
                          <p>₹ {item?.totalAmt}</p>
                        </div>
                        <div>
                          <h5 className="font-medium text-[#747070]">
                            Success Date
                          </h5>
                          <p>{item?.SuccessDate || "-"}</p>
                        </div>
                      </div>
                      <div className="grid">
                        <div>
                          <h3 className="text-sm mb-2">Amount</h3>
                          <p className="text-2xl font-bold  text-chatlook-darkblue">
                            ₹ {item?.withdrawal_amount}
                          </p>
                        </div>
                        <Button
                          className="!text-sm h-8"
                          onClick={() => handleButtonClick(item)}
                        >
                          Resend Webhook
                        </Button>
                      </div>
                    </div>
                  </TableRow>
                </Fragment>
              );
            })}
          </Table>
        </div>
      </div>
      <WithdrawModel
        isOpen={isEditModalBulkOpen}
        data={currentData}
        onClose={() => setIsEditModalBulkOpen(false)}
      />
      <WithdrawalAddModel
        onClose={() => setIsAddModalBulkOpen(false)}
        isOpen={isAddModalBulkOpen}
      />
    </>
  );
};

export default Withdrawal;
