// UI IMPORT
import { Icon, IconKeys } from "../icons";

// THIRD - PARTY IMPORT
import Flatpickr from "react-flatpickr";

export interface DatePickerProps {
  className?: string;
  name: string;
  label?: string;
  value?: any;
  options?: any;
  onChange?: (dates?: any) => void;
  icon?: IconKeys;
  rightIcon?: IconKeys;
  placeholder?: string;
  errors?: any;
}

const DatePicker = (props: DatePickerProps) => {
  const {
    className = "",
    label,
    icon,
    rightIcon,
    options = {},
    errors,
    name,
    ...rest
  } = props;

  const message = errors?.[name]?.message || "";
  return (
    <div className={`${className} w-fit flex flex-col relative`}>
      {label ? (
        <label className="text-ev-darkblue text-sm">{label}</label>
      ) : null}
      {icon ? (
        <Icon className="absolute me-4 text-xl ms-4" name={icon} />
      ) : null}
      <Flatpickr
        className={`text-sm ${icon ? "pl-11" : "pl-3"} ${
          rightIcon ? "pr-11" : "pr-3"
        } ${
          message ? "border-red-500" : ""
        } text-base h-10 rounded-md px-2 outline-none border-2 focus:border-chatlook-darkblue`}
        options={{
          ...options,
        }}
        {...rest}
      />
      {rightIcon ? (
        <Icon className="absolute right-0 me-4 text-xl" name={rightIcon} />
      ) : null}
      {message ? <span className="text-red-500 text-sm">{message}</span> : null}
    </div>
  );
};

export default DatePicker;
