import { api } from "../../utills/api";

export const withdrawalApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWithdrawal: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/withdrawal",
        body: params,
        method: "POST",
      }),
    }),
    AddWithdrawal: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/withdrawal/single/request",
        body: params,
        method: "POST",
      }),
    }),

    ResendWebhook: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/withdrawal/ResendWebhook",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetWithdrawalMutation,
  useAddWithdrawalMutation,
  useResendWebhookMutation,
} = withdrawalApi;
export const {
  endpoints: { getWithdrawal, AddWithdrawal, ResendWebhook },
} = withdrawalApi;
