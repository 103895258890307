import { useMemo } from "react";

// PROJECT IMPORT
import { RootState } from "../../utills/store";
import { claimutrApi } from "./claimutrApi";

// THIRD - PARTY IMPORT
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

interface ClaimutrState {
  claimiutrData: any;
  unClaimiutrData: any;
  checkData: any;
}

const initialState: ClaimutrState = {
  unClaimiutrData:[],
  claimiutrData: {},
  checkData: {},
};

export const claimutrSlice = createSlice({
  name: "claimutr",
  initialState,
  reducers: {
    clearStatement: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      claimutrApi.endpoints.getUnClaimutrData.matchFulfilled,
      (state, { payload }) => {
        state.unClaimiutrData = payload?.data || [];
      }
    );
    builder.addMatcher(
      claimutrApi.endpoints.getClaimutrData.matchFulfilled,
      (state, { payload }) => {
        state.claimiutrData = payload || {};
      }
    );
    builder.addMatcher(
      claimutrApi.endpoints.checkClaimData.matchFulfilled,
      (state, { payload }) => {
        state.checkData = payload || {};
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default claimutrSlice.reducer;
export const { clearStatement } = claimutrSlice.actions;

export const selectUnClaimutrData = (state: RootState) =>
  state.claimutr.unClaimiutrData;
export const useUnClaimutrData = () => {
  const details = useSelector(selectUnClaimutrData);
  return useMemo(() => details, [details]);
};

export const selectClaimutrData = (state: RootState) =>
  state.claimutr.claimiutrData;
export const useClaimutrData = () => {
  const details = useSelector(selectClaimutrData);
  return useMemo(() => details, [details]);
};
