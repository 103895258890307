// UI IMPORT
import { Icon, IconKeys } from "../../ui";

// PROJECT IMPORT
import { thousandSeparator } from "../../helperFunctions";

interface CardProps {
  index: number;
  value: number;
  text: string;
  icon: IconKeys;
  rupee?: boolean;
  count?: number;
  percentage?: number;
}
const Card = (props: CardProps) => {
  const { index, value, text, icon, rupee = true, count, percentage } = props;
  return (
    <div key={index} className={`my-1 `}>
      <div className="totaldepo-main rounded-lg h-full max-h-full flex justify-between">
        <div className="totaldepo-text" title={thousandSeparator(+value)}>
          <h4 className="text-ev-darkblue">
            {text}
            <span className="font-medium text-sm">
              {count ? ` (${count})` : null}
            </span>
          </h4>
          <div className="flex gap-1">
            {rupee ? <Icon name="Rupee" width={7} height={7} /> : null}
            <p className="text-ev-darkblue font-medium">
              {`${thousandSeparator(+value,false)}`}
              <span className=" text-chatlook-darkblue">
                {percentage ? `~ ${percentage}%` : null}
              </span>
            </p>
          </div>
        </div>
        <div className="totaldepo-img-sec">
          <div className="box-img">
            <Icon name={icon} className="max-w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
