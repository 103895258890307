import React, { CSSProperties, PropsWithChildren } from "react";

interface TableCellProps extends PropsWithChildren<{}>{
    className?:string;
    style?:CSSProperties;
    colSpan?:number;
    onClick?:(e?:any) => void;
}
const TableCell = (props:TableCellProps) => {
    const { className = "", style = {}, children,...rest } = props;
    return (
        <td className={`${className} open-padding no-wrap table-cell`} style={{ ...style }} {...rest}>
            {children}
        </td>
    );
};

export default TableCell;
