import { api } from "../../utills/api";

export const balanceSheetApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBalnceSummary: build.mutation<{ data: any }, any>({
      query: (params) => ({ 
        url: "/statement/balancesheet",
        body: params,
        method: "POST",
      }),
      invalidatesTags: ["Dashboard"],
    }),
  }),
});



export const {useGetBalnceSummaryMutation  } = balanceSheetApi;
export const {
  endpoints: {getBalnceSummary },
} = balanceSheetApi;
