import React, { useRef } from "react";
import { LOGOUT_EVENTS, LOGOUT_TIME } from "./constant";
import { useAppDispatch } from "./utills/hooks";
import { logout, showToaster } from "./helperFunctions";

const addListeners = (events: any, cb: any) => {
    events.forEach((event: any) => window.addEventListener(event, cb));

    return () => {
        events.forEach((event: any) => window.removeEventListener(event, cb));
    };
};

export const useLogout = (time: number = LOGOUT_TIME) => {                                                                                                                                                                             
    const listenRef = useRef<any>();                                                                                                                                                                             
    const dispatch = useAppDispatch()                                                                                                                                                                             
                                                            
    React.useEffect(() => {                                                                                                                                                                             
        addListeners(LOGOUT_EVENTS, (e: any) => {                                                                                                                                                                             
                                                                        
            clearTimeout(listenRef.current)                                                                                                                                                                             
            listenRef.current = setTimeout(() => {                                                                                                                                                                             
                    logout(dispatch)                                                                                                                                                                             
                    showToaster('You have been logged out forcefully','Error')                                                                                                                                                                             
            }, time);                                                                                                                                                                             
        });                                                                                                                                                                             
                                                            
        return () => {                                                                                                                                                                             
            clearTimeout(listenRef.current)                                                                                                                                                                             
        }     
         // eslint-disable-next-line                                                                                                                                                                         
    }, []);                                                                                                                                                                             
};        