import { api } from "../../utills/api";

export const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSummary: build.query<{ data: any }, void>({
      query: () => "/dashboard/brief",
      providesTags: ["Dashboard"],
    }),
    getDepositChart: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/dashboard/chart/Deposit",
        body: params,
        method: "POST",
      }),
    }),
    getWithdrawalChart: build.mutation<{ data: any }, any>({
      query: (params) => ({
        url: "/dashboard/chart/Withdrawal",
        body: params,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetSummaryQuery,
  useGetDepositChartMutation,
  useGetWithdrawalChartMutation,
} = dashboardApi;
export const {
  endpoints: { getSummary, getDepositChart, getWithdrawalChart },
} = dashboardApi;
