import { useState } from "react";
import { Button, Input, Loader, Select } from "../../ui";
import { showToaster } from "../../helperFunctions";
import { useAddWithdrawalMutation } from "./withdrawalApi";
import { useForm } from "react-hook-form";
import { withdrawalType } from "./mock";

const WithdrawalAddModel = (props: any) => {
  const { isOpen, onClose } = props;

  const [AddWithdrawal] = useAddWithdrawalMutation();

  const { register, handleSubmit, reset, getValues, setValue } =
    useForm({});
  const [isLoader, setIsLoader] = useState(false);

  const onSubmit = async () => {
    setIsLoader(true);

    try {
      const values = getValues();
      const res: any = await AddWithdrawal(values).unwrap();
      if (res?.status == false) {
        showToaster(res?.message || "", "Error");
        setIsLoader(false);
      } else {
        showToaster(res?.message);
        onClose();
        setIsLoader(false);
        reset()
      }
    } catch (e) {
      setIsLoader(false);
    }
  };

  if (!isOpen) return null;

  return (
    <form
      className="fixed inset-0 z-50 flex items-center justify-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={() => {
          onClose();
        }}
      />
      <div className="modal-container bg-white w-4/12 md:w-8/12 sm:w-10/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-4 text-left ">
          <div className="flex justify-between items-center pb-3 mx-6 ">
            <p className="text-2xl font-bold text-ev-darkblue">
              Add Withdrawal
            </p>
            <div className="modal-close cursor-pointer z-50" onClick={onClose}>
              <svg
                className="fill-current text-black"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path d="M18 1.3L16.7 0 9 7.7 1.3 0 0 1.3 7.7 9 0 16.7 1.3 18 9 10.3l7.7 7.7 1.3-1.3L10.3 9z"></path>
              </svg>
            </div>
          </div>
          <div className="gap-2 items-end w-100 px-6 pt-3">
            <Select
              className="w-full"
              lableClassName="mt-2"
              name="withdrawalType"
              label="Filter"
              options={withdrawalType}
              register={register}
            />
            <Input
              type="number"
              lableClassName="mt-2"
              className="me-2 w-full"
              name="withdrawalAmt"
              placeholder="Enter withdrawal Amount"
              label="Withdrawal Amount"
              register={register}
            />
            <Input
              type="text"
              lableClassName="mt-2"
              className="me-2 w-full"
              name="withdrawalRefId"
              placeholder="Enter Withdrawal Ref ID"
              label="Withdrawal Ref ID"
              register={register}
            />
            <Input
              type="text"
              lableClassName="mt-2"
              className="me-2 w-full"
              name="custName"
              placeholder="Enter Customer Name"
              label="Customer Name"
              register={register}
            />
            <Input
              type="number"
              lableClassName="mt-2"
              className="me-2 w-full"
              name="acnumber"
              placeholder="Enter A/c Number"
              label="A/c Number"
              register={register}
            />
            <Input
              type="text"
              lableClassName="mt-2"
              className="me-2 w-full"
              name="ifsc_code"
              placeholder="Enter IFSC Code"
              label="IFSC Code"
              onChange={(e) =>
                setValue("ifsc_code", e.target.value.toUpperCase())
              }
              register={register}
            />
            <div className="flex justify-center my-4">
              {!isLoader ? (
                <Button type="submit" className="me-4">
                  Apply
                </Button>
              ) : (
                <div className="flex item-center justify-center">
                  <Loader />
                </div>
              )}
              <Button
                variant="Transaparent"
                onClick={() => {
                  reset();
                }}
              >
                Clear
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default WithdrawalAddModel;
