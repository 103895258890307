import { useNavigate } from "react-router";

// UI IMPORT
import { Button, Icon, Input, Loader, Password } from "../../ui";

// PROJECT IMPORT
import { useLoginMutation } from "./authApi";
import { showToaster } from "../../helperFunctions";
import { useDetails } from "./authSlice";
import { LoginFormTypes } from "./types";

// THIRD - PARTY IMPORT
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const Login = () => {
  const details = useDetails();
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();

  const schema = yup.object({
    username: yup.string().required("Please enter a username"),
    password: yup.string().required("Please enter a password"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver<LoginFormTypes>(schema),
  });

  const onSubmit = async (values: LoginFormTypes) => {
    try {
      const res = await login(values).unwrap();
      showToaster(res?.message);
      navigate(res?.sidebar?.[0]?.route);
    } catch (err: any) {
      console.error("Login Error =-=>", err);
    }
  };

  const logo: any = process.env.REACT_APP_OUTER_LOGO;

  if (isLoading) {
    return (
      <>
        <div className="w-[100vw] h-[100vh] z-[9999] flex items-center justify-center">
          <Loader />
        </div>
      </>
    );
  }

  return (
    <>
      <form
        className="grid grid-cols-2 md:grid-cols-1 sm:grid-cols-1 h-screen"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="bg-[#F2F3F8] flex">
          <div className="login-main rounded-xl bg-chatlook-white w-7/12 m-auto ">
            <div className="flex items-center justify-center py-3">
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(logo)}`}
                  alt="Logo"
                />
              </div>
                        <div className="welc-text text-center mb-10">
              <h4>Welcome back!</h4>
              <p className="mt-1">Login to your account</p>
            </div>
            <div className="space-y-5 pb-10">
              <Input
                className="!w-full"
                inputClassName="!h-12"
                icon="EmailIcon"
                name="username"
                placeholder="Email Address"
                errors={errors}
                register={register}
              />
              <Password
                className="!w-full"
                inputClassName="!h-12"
                name="password"
                placeholder="Password"
                errors={errors}
                register={register}
              />
              {/* <p className="text-ev-lightblue text-end my-2">
                Recover Password
              </p> */}
            </div>
            <div className="flex items-center justify-center w-full">
              <Button type="submit" className="!px-20">
                Login
              </Button>
            </div>
          </div>
        </div>
        <div className="bg-chatlook-darkblue flex md:hidden sm:hidden items-center">
          <div className="w-8/12 m-auto">
            <Icon name={"Logohero"} className="max-w-full m-auto" />
            <p className="text-ev-white text-center mt-4">
              Login is the act of entering credentials to access an account or
              system.
            </p>
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
