import { useMemo } from "react";

// PROJECT IMPORT
import { RootState } from "../../utills/store";
import { authApi } from "./authApi";

// THIRD - PARTY IMPORT
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

interface AuthState {
  user: any;
  details:any;
}

const initialState: AuthState = {
  user: {},
  details:{}
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuth: (state) => {
      state.user = {}
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
      }
    );
    builder.addMatcher(
      authApi.endpoints.getLogo.matchFulfilled,
      (state, { payload }) => {
        state.details = payload;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export default authSlice.reducer;
export const { clearAuth } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;
export const useUser = () => {
  const user = useSelector(selectUser);
  return useMemo(() => user, [user]);
};

export const selectDetails = (state: RootState) => state.auth.details;
export const useDetails = () => {
  const details = useSelector(selectDetails);
  return useMemo(() => details, [details]);
};